/**
 * index.js
 * - All our useful JS goes here, awesome!
 * - JQuery
 */

 console.log("JavaScript is amazing!");

 $(document).ready(function(){


    $("#highlight").click(function(){
        $(this).remove();
    });

    // Click event for any anchor tag that's href starts with #
    $('a[href^="#"]').click(function(event) {

        // The id of the section we want to go to.
        var id = $(this).attr("href");

        // An offset to push the content down from the top.
        var offset = 60;

        // Our scroll target : the top position of the
        // section that has the id referenced by our href.
        var target = $(id).offset().top - offset;

        // The magic...smooth scrollin' goodness.
        $('html, body').animate({scrollTop:target}, 500);

        //prevent the page from jumping down to our section.
        event.preventDefault();
    });



    $(".list-item button").click(function(){
        var listItem = $(this).closest(".list-item");
        var list = $(this).closest(".list");
        listItems = $(this).closest(".list").find(".list-item");

        if ($(listItem).hasClass("active")) {
            $(listItem).removeClass("active");
            $(listItems).removeClass("active");
            //$("#about").animate({right: "-700px"}, 2000);
        }
        else {
            $(listItems).each(function( index ) {
                $(this).removeClass("active");
            });
            $(listItem).addClass("active");
        }
    });

    var intervalId = window.setInterval(function(){
        /*
        "assets/images/marketing/XVP_ELR-.jpeg",
            "/images/marketing/9o5t6QUp.jpeg",
            "/assets/images/marketing/xLK_qp18.jpeg",
            "/images/marketing/WLDJLLVv.jpeg",
            "qLuchfpU.jpeg"
         "../assets/images/marketing/9o5t6QUp.5c2e88d2.jpeg",
            "/assets/images/marketing/9o5t6QUp.5c2e88d2.jpeg",
            "/images/marketing/9o5t6QUp.5c2e88d2.jpeg",
            "/marketing/9o5t6QUp.5c2e88d2.jpeg",
            */
        const numbers = [
            'image0',
            'image1',
            'image2',
            'image3',
            'image4',
            'image5',
            'image6',
            'image7',
            'image8',
            'image9',
            'image10',
            'image11',
            'image12',
            'image13',
            'image14',
            'image15',
            'image16',
            'image17',
            'image18',
            'image19',
            'image20',
            'image21',
            'image22',
            'image23',
            'image24',
            'image25',
            'image26',
            'image27',
            'image28',
            'image29',
            'image30',
            'image31',
            'image32',
            'image33',
            'image34',
            'image35',
            'image36',
            'image37'
        ]

        const images = [
            "A0Ud0j38.c1e862a8.jpeg",
            "9o5t6QUp.5c2e88d2.jpeg",
            "XVP_ELR-.d2225d6b.jpeg",

            "aNf0E9K4.5d352a31.jpeg",
            "BHSxUTKB.9cd2e154.jpeg",
            "d1jJf8SI.96d82949.jpeg",
            "DibDPqmI.56ed17d0.jpeg",
            "dtYsJnLU.5ec0126c.jpeg",
            "M34woOgk.5e8ee5cf.jpeg",

            "Nfsoe_C6.bbd58a4b.jpeg",

            "nxHY590o.5f58a0cf.jpeg",
            "vaMuPou8.dc0eab90.jpeg",
            "XVP_ELR-.d2225d6b.jpeg",
            "y8Rn0_48.c5eac07c.jpeg",
            "DEZHtBKA.3f1b2953.jpeg",
            "DHE_XeKI.8ccd1733.jpeg",
            "eZtotkxI.0cf38369.jpeg",
            "gsEOoB7Q.512ae3c4.jpeg",
            "GYnlYgOA.6c5c98e7.jpeg",
            "H8qqbAdF.73177111.jpeg",
            "kF_s8orY.c3e720db.jpeg",
            "Nfsoe_C6.bbd58a4b.jpeg",
            "Nun5IOos.4fce5e71.jpeg",
            "pVnvbehY.4beb46aa.jpeg",
            "qLuchfpU.bb0116be.jpeg",
            "W23Ysa8R.8c3386b1.jpeg",
            "ZL2AJBsJ.48a95d24.jpeg",
            "Zpt_76vt.bf42723a.jpeg",
            "zmlxfi-n.d4c621c3.jpeg",
            "B3ZBTjXI.122bc050.jpeg"
        ];
        /*
        var img=new Image();
        for (let i = 0; i < images.length; i++) {
            text += cars[i] + "<br>";
        }
        images.forEach(element => 
            img.src=i;
        );
        */

        //var img = Math.floor(Math.random() * images.length);
        //var img = images[Math.floor(Math.random() * images.length)];
        var number = numbers[Math.floor(Math.random() * numbers.length)];
        console.log(number);

        $('#cover-image').fadeTo('slow', 0.7, function()
        {
            $(this).attr("image", number);
            //$(this).css('background-image', 'url(' + img + ')');
            //$(this).attr('src', '/'+img);
        }).fadeTo('slow', 1);
    }, 5000);

 

  });